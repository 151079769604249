import React from "react"
import PropTypes from "prop-types"

import loadable from "@loadable/component"

const ReviewDisplay = loadable(() => import("../ReviewDisplay"))
// const Share = loadable(() => import('./Share'))
const Details = loadable(() => import("./Details"))

const UtilityHeading = ({ title, details, reviewId }) => (
  <>
    <div className="mb-8">
      <h1 className="mb-2 text-2xl font-bold">{title}</h1>
      <div className="flex items-center leading-none text-blue-500">
        <ReviewDisplay
          reviewId={reviewId}
          textClasses="font-bold text-sm"
          nameLink
          byType="Current Page"
        />
        {/* <span className="px-2">&bull;</span>
        <Share /> */}
      </div>
    </div>
    {details.length > 0 && <Details items={details} />}
  </>
)

export default UtilityHeading

UtilityHeading.propTypes = {
  title: PropTypes.string,
  details: PropTypes.array,
  reviewId: PropTypes.string,
}
